import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
// import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout.js";

const Page = () => {
  return (
    <Layout>
      <Head title="David Apfel" />
      <h3>David Apfel</h3>
      {/* <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Pahrump, NV
      </h4> */}
      <p>
        <OutboundLink href="https://ello.co/davidapfel">Ello</OutboundLink>
      </p>
      <p>
        Photography has been my most enduring habit, followed by music
        composition and performance (drums, not exclusively). Holding a camera,
        I give myself permission to stop, stare, and enjoy things I'd otherwise
        pass by. I crave the scent of forest, sound of water, and openness of
        sky, and get far too little of each. Former engineering lab tech and
        industrial parts designer by day; husband, father, friend, and sponsor
        by night...one day at a time.
      </p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/15959064/ello-optimized-00db368f.jpg"
        alt="David Apfel Art Photography"
      />
      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15959066/ello-optimized-8b278c7e.jpg"
        alt="David Apfel Art Photography"
      />
      <p>Mist My Morning Meditation | Sighchaotic Saturday Series, pt. 7</p>

      <p>Fuji Superia 400</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15961942/ello-optimized-49f7401e.jpg"
        alt="David Apfel Art Photography"
      />
      <p>Monarch (Danaus plexippus)</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15961943/ello-optimized-c5540faa.jpg"
        alt="David Apfel Art Photography"
      />
      <p>Monday's Macro & Cheese, pt.3</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/4529863/ello-optimized-85bacd08.jpg"
        alt="David Apfel Art Photography"
      />
      <p>Through the Woods, Wetly</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/7904738/ello-optimized-b0079fd0.jpg"
        alt="David Apfel Art Photography"
      />
      <p>Home(r) on the Range - Homer, Alaska</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/9996128/ello-optimized-31b679d1.jpg"
        alt="David Apfel Art Photography"
      />
      <p>Minimalist Monday #5</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15768448/ello-optimized-dfcb888c.jpg"
        alt="David Apfel Art Photography"
      />
      <p>A Wrinkle in Space | Pittsburgh, PA Oct. 2022</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15941706/ello-optimized-820d3b0f.jpg"
        alt="David Apfel Art Photography"
      />
      <p>The Mentor | WHM 2</p>
    </Layout>
  );
};

export default Page;
